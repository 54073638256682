<template>
  <v-app>
    <v-content class="white">
      <section class="shopping_cart">
          <h1>Carrito de compra</h1>
          <v-divider class="my-3"></v-divider>
          <div class="cart-list" v-if="shoppingCart.menus.length>0">
            <h4>Menús</h4>
            <v-divider class="my-3"></v-divider>
            <div class="cart-item" v-for="(item,i) in shoppingCart.menus" :key="i">
              <itemList  :item="item" :type="'menu'" />
            </div>
          </div>
          <div class="cart-list" v-if="shoppingCart.dishes.length>0">
            <h4>Platos</h4>
            <v-divider class="my-3"></v-divider>
            <div class="cart-item" v-for="(item,i) in shoppingCart.dishes" :key="i">
              <itemList  :item="item" :type="'dishes'" />
            </div>
          </div>
      </section>
    </v-content>
  </v-app>
</template>

<script>
 import itemList from './components/ItemList'
  export default {
    name: 'ShoppingCart',
   components: { itemList },
    data(){

			return {
        shoppingCart: this.$store.state.shoppingCart,
      }
    },
    created: function () {
      //console.log(this.shoppingCart.dishes.length)
    },
  	computed:{

    }
  }
</script>
