<template>
  <div>
    <v-row class="align-center">
      <v-col md="2">
        <v-img
          class=""
          :src="image"
          :lazy-src="image"
          aspect-ratio="5"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
        </v-img>
      </v-col>
      <v-col md="6">
        <template v-if="type == 'dishe'">
          <router-link class="landing_primary--text card-info-link" :to="{name: 'dishe-details', params:{ landing: $route.params.landing, id: item.id}}">
            <h3 class="description-letter landing_primary--text" v-text="name"></h3>
          </router-link>
        </template>
        <template v-if="type == 'menu'">
          <router-link class="landing_primary--text card-info-link" :to="{name: 'menu-details', params:{ landing: $route.params.landing, id: item.id}}">
            <h3 class="description-letter landing_primary--text" v-text="name"></h3>
          </router-link>
        </template>
      </v-col>
      <v-col md="2">
        <v-row class="align-center">
          <v-col>
            <v-btn color="error" class="ma-0" small>-</v-btn>
          </v-col>
          <v-col>
            <p class="text-center pa-0 ma-0" small
            v-text="item.cant"
            ></p>
          </v-col>
          <v-col>
            <v-btn @click="addCard()" color="success" class="ma-0" small>+</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="2">
        <p class="text-center pa-0 ma-0" v-text="total"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>

	export default {
    name: 'AddToCard',
    props: ['item','type'],
    data() {
      //console.log('test')
      return {
        itemI: this.item.item,
        cant: this.item.cant,
        url_img: process.env.VUE_APP_IMG_URL_API,
      }
    },
    methods: {
      addCard() {
        let item = this.item;
        let type = this.type;
        //console.log(type)
        let payload = {
          'item' : item,
          'type' : type
        };
        this.$store.commit('ADD_ITEM',payload);
      }
    },
    computed: {
      name(){
				return this.itemI.name[this.$store.getters.language] ? this.itemI.name[this.$store.getters.language] : this.itemI.name.es;
			},
      image(){
				return this.type == 'dishe' ? `${this.url_img}images/dishes/${this.itemI.image_dish}` : this.type == 'menu' ? `${this.url_img}images/menu/${this.itemI.image_menu}` : '';
      },
      total(){
        return this.itemI.price * this.item.cant;
      }
    }
  }

</script>
